import { default as _91id_93YEFRbx4od7Meta } from "/vercel/path0/pages/board/community/[id].vue?macro=true";
import { default as indexrTZMYS56AEMeta } from "/vercel/path0/pages/board/community/index.vue?macro=true";
import { default as writeCAu6S1kkwqMeta } from "/vercel/path0/pages/board/community/write.vue?macro=true";
import { default as _91id_93wDIBTPdrh8Meta } from "/vercel/path0/pages/board/notice/[id].vue?macro=true";
import { default as indextbyWiW6TkeMeta } from "/vercel/path0/pages/board/notice/index.vue?macro=true";
import { default as write7S8Z2CmLUhMeta } from "/vercel/path0/pages/board/notice/write.vue?macro=true";
import { default as confirm2uAIxw9fKZMeta } from "/vercel/path0/pages/confirm.vue?macro=true";
import { default as _91vehicleId_93YFoDgTf7m7Meta } from "/vercel/path0/pages/diary/[vehicleId].vue?macro=true";
import { default as indexmNIT76knucMeta } from "/vercel/path0/pages/diary/index.vue?macro=true";
import { default as _91_46_46_46slug_93ngvA2sznVNMeta } from "/vercel/path0/pages/document/[...slug].vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as mainWBumncU2ZSMeta } from "/vercel/path0/pages/main.vue?macro=true";
import { default as _91_46_46_46slug_93TZwdJG1X62Meta } from "/vercel/path0/pages/patch/[...slug].vue?macro=true";
import { default as indexlj5aTopGWCMeta } from "/vercel/path0/pages/setting/index.vue?macro=true";
import { default as edit6yvuxnXDIyMeta } from "/vercel/path0/pages/setting/user/edit.vue?macro=true";
import { default as indexTWLQY51K9xMeta } from "/vercel/path0/pages/setting/user/index.vue?macro=true";
import { default as _91vehicleId_93BmQTYwlYgfMeta } from "/vercel/path0/pages/vehicles/[vehicleId].vue?macro=true";
import { default as indexdkgr5gN20kMeta } from "/vercel/path0/pages/vehicles/index.vue?macro=true";
import { default as newzir8b95Ae3Meta } from "/vercel/path0/pages/vehicles/new.vue?macro=true";
export default [
  {
    name: "board-community-id",
    path: "/board/community/:id()",
    meta: _91id_93YEFRbx4od7Meta || {},
    component: () => import("/vercel/path0/pages/board/community/[id].vue").then(m => m.default || m)
  },
  {
    name: "board-community",
    path: "/board/community",
    meta: indexrTZMYS56AEMeta || {},
    component: () => import("/vercel/path0/pages/board/community/index.vue").then(m => m.default || m)
  },
  {
    name: "board-community-write",
    path: "/board/community/write",
    meta: writeCAu6S1kkwqMeta || {},
    component: () => import("/vercel/path0/pages/board/community/write.vue").then(m => m.default || m)
  },
  {
    name: "board-notice-id",
    path: "/board/notice/:id()",
    meta: _91id_93wDIBTPdrh8Meta || {},
    component: () => import("/vercel/path0/pages/board/notice/[id].vue").then(m => m.default || m)
  },
  {
    name: "board-notice",
    path: "/board/notice",
    meta: indextbyWiW6TkeMeta || {},
    component: () => import("/vercel/path0/pages/board/notice/index.vue").then(m => m.default || m)
  },
  {
    name: "board-notice-write",
    path: "/board/notice/write",
    meta: write7S8Z2CmLUhMeta || {},
    component: () => import("/vercel/path0/pages/board/notice/write.vue").then(m => m.default || m)
  },
  {
    name: "confirm",
    path: "/confirm",
    component: () => import("/vercel/path0/pages/confirm.vue").then(m => m.default || m)
  },
  {
    name: "diary-vehicleId",
    path: "/diary/:vehicleId()",
    meta: _91vehicleId_93YFoDgTf7m7Meta || {},
    component: () => import("/vercel/path0/pages/diary/[vehicleId].vue").then(m => m.default || m)
  },
  {
    name: "diary",
    path: "/diary",
    meta: indexmNIT76knucMeta || {},
    component: () => import("/vercel/path0/pages/diary/index.vue").then(m => m.default || m)
  },
  {
    name: "document-slug",
    path: "/document/:slug(.*)*",
    meta: _91_46_46_46slug_93ngvA2sznVNMeta || {},
    component: () => import("/vercel/path0/pages/document/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexe9Brt5DfdhMeta || {},
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: logint0AWlhQgM0Meta || {},
    component: () => import("/vercel/path0/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "main",
    path: "/main",
    meta: mainWBumncU2ZSMeta || {},
    component: () => import("/vercel/path0/pages/main.vue").then(m => m.default || m)
  },
  {
    name: "patch-slug",
    path: "/patch/:slug(.*)*",
    meta: _91_46_46_46slug_93TZwdJG1X62Meta || {},
    component: () => import("/vercel/path0/pages/patch/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "setting",
    path: "/setting",
    meta: indexlj5aTopGWCMeta || {},
    component: () => import("/vercel/path0/pages/setting/index.vue").then(m => m.default || m)
  },
  {
    name: "setting-user-edit",
    path: "/setting/user/edit",
    meta: edit6yvuxnXDIyMeta || {},
    component: () => import("/vercel/path0/pages/setting/user/edit.vue").then(m => m.default || m)
  },
  {
    name: "setting-user",
    path: "/setting/user",
    meta: indexTWLQY51K9xMeta || {},
    component: () => import("/vercel/path0/pages/setting/user/index.vue").then(m => m.default || m)
  },
  {
    name: "vehicles-vehicleId",
    path: "/vehicles/:vehicleId()",
    meta: _91vehicleId_93BmQTYwlYgfMeta || {},
    component: () => import("/vercel/path0/pages/vehicles/[vehicleId].vue").then(m => m.default || m)
  },
  {
    name: "vehicles",
    path: "/vehicles",
    meta: indexdkgr5gN20kMeta || {},
    component: () => import("/vercel/path0/pages/vehicles/index.vue").then(m => m.default || m)
  },
  {
    name: "vehicles-new",
    path: "/vehicles/new",
    meta: newzir8b95Ae3Meta || {},
    component: () => import("/vercel/path0/pages/vehicles/new.vue").then(m => m.default || m)
  }
]